/* eslint-disable */
/**
 * 该文件是为了按需加载，剔除掉了一些不需要的框架组件。
 * 减少了编译支持库包大小
 *
 * 当需要更多组件依赖时，在该文件加入即可
 */
import Vue from 'vue';
import 'ant-design-vue/dist/antd.less';
import '../global.less';

import {
    ConfigProvider,
    Pagination, 
    Layout,
    Space,
    Input,
    InputNumber,
    Button,
    Switch,
    Radio,
    Checkbox,
    Select,
    Card,
    Form,
    FormModel,
    Row,
    Col,
    Modal,
    Table,
    Tabs,
    Icon,
    Badge,
    Popover,
    Dropdown,
    List,
    Avatar,
    Breadcrumb,
    Steps,
    Spin,
    Menu,
    Drawer,
    Tooltip,
    Alert,
    Tag,
    Divider,
    DatePicker,
    TimePicker,
    Upload,
    Progress,
    Skeleton,
    Popconfirm,
    message,
    Tree,
    TreeSelect,
    notification,
    Cascader,
    transfer
  } from 'ant-design-vue'
  // import VueCropper from 'vue-cropper'
  
  Vue.use(ConfigProvider)
  Vue.use(Tree)
  Vue.use(TreeSelect)
  Vue.use(Layout)
  Vue.use(Input)
  Vue.use(InputNumber)
  Vue.use(Button)
  Vue.use(Switch)
  Vue.use(Radio)
  Vue.use(Checkbox)
  Select.props.getPopupContainer.default = triggerNode => {
    return triggerNode.parentNode
  }
  Vue.use(Select)
  Vue.use(Space)
  Vue.use(transfer)
  Vue.use(Pagination)
  Vue.use(Card)
  Vue.use(Form)
  Vue.use(FormModel)
  Vue.use(Row)
  Vue.use(Col)
  Vue.use(Modal)
  Vue.use(Table)
  Vue.use(Tabs)
  Vue.use(Icon)
  Vue.use(Badge)
  Vue.use(Popover)
  Vue.use(Dropdown)
  Vue.use(List)
  Vue.use(Avatar)
  Vue.use(Breadcrumb)
  Vue.use(Steps)
  Vue.use(Spin)
  Vue.use(Menu)
  Vue.use(Drawer)
  Vue.use(Tooltip)
  Vue.use(Alert)
  Vue.use(Tag)
  Vue.use(Divider)
  Vue.use(DatePicker)
  Vue.use(TimePicker)
  Vue.use(Upload)
  Vue.use(Progress)
  Vue.use(Skeleton)
  Vue.use(Popconfirm)
  // Vue.use(VueCropper)
  Vue.use(notification)
  Vue.use(Cascader)
  
  Vue.prototype.$confirm = Modal.confirm
  Vue.prototype.$message = message
  Vue.prototype.$notification = notification
  Vue.prototype.$info = Modal.info
  Vue.prototype.$success = Modal.success
  Vue.prototype.$error = Modal.error
  Vue.prototype.$warning = Modal.warning