<template>
  <a-config-provider :locale="locale">
    <div id="app">
      <keep-alive :include="['brandList']">
        <router-view/>
      </keep-alive>
    </div>
  </a-config-provider>
</template>

<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'

export default {
  name: "App",
  data () {
    return {
      locale: zhCN
    }
  },
  mounted() {
    
  }
};
</script>

<style lang="less">
html,body{
  font-size: 62.5%;
  width: 100%;
  height: 100vh;
}
#app {
  font-family: pingfang SC,helvetica neue,arial,hiragino sans gb,microsoft yahei ui,microsoft yahei,simsun,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  width: 100%;
}
</style>
