import Vue from 'vue';
/*使用方法v-numberOnly,限制input只能输入正整数*/
Vue.directive('numberOnly', {
    bind(el) {
        el.handler = function () {
            var val = el.value.replace(/\D+/, "");
            el.value = val == 0 ? '' : val;
        };
        el.addEventListener("input", el.handler);
    },
    unbind(el) {
        el.removeEventListener("input", el.handler);
    }
})
/*使用方法v-numberOnly,限制input只能输入字母加数字*/
Vue.directive('numberAndLetterOnly', {
    bind(el) {
        el.handler = function () {
            var val = el.value.replace(/[\W]/g, '');
            el.value = val == 0 ? '' : val;
        };
        el.addEventListener("input", el.handler);
    },
    unbind(el) {
        el.removeEventListener("input", el.handler);
    }
})