import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

let routes = [{
    path: '/',
    redirect: 'index',
    meta: {
        title: '首页',
    }
}, {
    path: '/index',
    name: 'index',
    component: () => import('@/views/Index.vue'),
    meta: {
        title: '首页',
    }
},
//专业版
{
    path: '/buyCombe',
    name: 'buyCombe',
    component: () => import('@/views/combe/BuyCombe.vue'),
    meta: {
        title: '购买套餐',
    }
},
{
    path: '/buyRecord',
    name: 'buyRecord',
    component: () => import('@/views/combe/BuyRecord.vue'),
    meta: {
        title: '购买记录',
    }
},
{
    path: '/choiceSettle',
    name: 'choiceSettle',
    component: () => import('@/views/combe/ChoiceSettle.vue'),
    meta: {
        title: '在线支付',
    }
},
//标准版
{
    path: '/queryVin',
    name: 'queryVin',
    component: () => import('@/views/standard/QueryVin.vue'),
    meta: {
        title: '查vin码',
    }
},
{
    path: '/queryPart',
    name: 'queryPart',
    component: () => import('@/views/standard/QueryPart.vue'),
    meta: {
        title: '查零件号',
    }
},
{
    path: '/partDetail/:goodsId',
    name: 'partDetail',
    component: () => import('@/views/standard/PartDetail.vue'),
    meta: {
        title: '零件详情',
    }
},
{
    path: '/queryBrand',
    name: 'queryBrand',
    component: () => import('@/views/standard/QueryBrand.vue'),
    meta: {
        title: '查品牌件',
    }
},
{
    path: '/queryOrigin/:type',
    name: 'queryOrigin',
    component: () => import('@/views/standard/QueryOrigin.vue'),
    meta: {
        title: '查品牌件',
    }
},
{
    path: '/brandList/:brandId/:brandName',
    name: 'brandList',
    component: () => import('@/views/standard/BrandList.vue'),
    meta: {
        title: '配件列表',
    }
},
{
    path: '/choiceCarModel',
    name: 'choiceCarModel',
    component: () => import('@/views/standard/ChoiceCarModel.vue'),
    meta: {
        title: '选择品牌',
    }
},
//后台页面
{
    path: '/package',
    name: 'package',
    component: () => import('@/views/manage/Package.vue'),
    meta: {
        title: '套餐管理',
    }
}, {
    path: '/buyRecrd',
    name: 'buyRecrd',
    component: () => import('@/views/manage/BuyRecrd.vue'),
    meta: {
        title: '购买记录',
    }
}, {
    path: '/expireRemind',
    name: 'expireRemind',
    component: () => import('@/views/manage/ExpireRemind.vue'),
    meta: {
        title: '到期提醒',
    }
}, {
    path: '/queryEPC/:vin',
    name: 'queryEPC',
    component: () => import('@/views/professional/QueryEPC.vue'),
    meta: {
        title: 'EPC配件查询',
    }
}, {
    path: '/partList',
    name: 'partList',
    component: () => import('@/views/professional/PartList.vue'),
    meta: {
        title: '配件列表',
    }
}]

let router = new Router({
    mode: 'history',
    scrollBehavior() { },
    routes: routes,
});

router.beforeEach((to, from, next) => {
    next()
});
export default router;
