import Vue from "vue";
import App from "./App.vue";
import router from './router/index';
import moment from 'moment';
import 'moment/locale/zh-cn';
moment.locale('zh-cn');
import '@/utils/component_use.js';
import '@/utils/rem'
import '@/directives'
Vue.config.productionTip = false;

// import Vconsole from 'vconsole'
// if(process.env.NODE_ENV != '1'){
//   let vConsole = new Vconsole()
//   Vue.use(vConsole)
// }


new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
